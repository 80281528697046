.message {
  margin: 0;
  margin-bottom: 2em;
  font-size: 1.2em;
  width: 100%;
  padding: 0;

  pre {
    background: black;
    overflow: hidden;
    position: relative;
  }

  .code-block {
    color: white;
    .code {
      overflow-x: auto;
      padding: 1em;
      width: 100%;
    }
    code {
      white-space: pre-wrap !important;
      display: block;
      font-family: var(--font-family-monospace);
    }
  }

  .code-block-header {
    padding: 0.5em;
    display: flex;
  }

  .copy-answer {
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 0.5em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    height: 3.8vh;
    gap: 0.4em;
    margin-left: auto;
    cursor: pointer;
    border: none;
    max-width: max-content;
    font-family: var(--font-family-sans-serif);
  }

    .numbered-list-item {
      // padding-left: 8px !important;
      /* Adjust this value according to your needs */
      // list-style-position: inside;
      // list-style-position: outside;
      list-style-type: none;
    }

  .copy-answer:hover {
    color: #fff;
    transform: none;
  }

  .message-body {
    max-width: 75%;

    .shareContainer {
      display: none;
    }
  }

  @media (min-width: 450px) {
    .message-body .shareContainer {
      display: block;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 1em;
    ol {
        padding-left: 1.2em;
      }
  }

  .card-body > * {
    margin: 0;
    padding: 0;
  }

  .card {
    margin-top: 0;
  }
}

.message.student {
  flex-direction: row-reverse;
}

.message pre {
  overflow: hidden;
  position: relative;
}

.message .code-block {
  color: white;
}

.code-block .code {
  overflow-x: auto;
  padding: 1em;
  width: 100%;
}

.code-block code {
  white-space: pre-wrap !important;
  display: block;
}

.code-block-header {
  padding: 0.5em;
  background: #333;
}

.copy-answer {
  font-size: 1.5vh;
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 0.4em;
  margin-left: auto;
  cursor: pointer;
  padding: 0.5em;
  background-color: transparent;
  border-radius: 5px;
  color: white;
  width: 18vw;
  flex-direction: row-reverse;
  background: hsla(234, 91%, 47%, 1);

  background: linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0B21E3", endColorstr="#AD00F7", GradientType=1 );
  transition: background-color 0.3s ease;
}
.copy-answer:hover {
  color: white;
}

.code-block .copy-answer {
  color: white;
  background: none !important;
  font-family: "Poppins", sans-serif;
  width: max-content;
  padding: 3px !important;
}

.code-block .copy-answer:hover {
  background: #333 !important;
  color: white;
}

.message {
  margin-bottom: 2em;
}

.message .message-body {
  max-width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.message .card-body {
  display: flex;
  flex-direction: column;
  gap: 1em;
  word-break: break-word;
  ol {
      padding-left: 1.5em;
  }
  li {
    margin-bottom: 0.5em;
  }
}

.message .card-body > * {
  margin: 0;
  padding: 0;
}

.message .card {
  margin-top: 0;
}
.message .card {
  background: hsla(234, 91%, 47%, 1);

  background: linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0B21E3", endColorstr="#AD00F7", GradientType=1 );
}

.bg-student {
  background-color: #817d7d !important;
  background: #817d7d !important;
}

.btnContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 1vh;
  margin-left: 0;
}

.gradientButton{
  color: white;
  border-radius: 5px;
  font-size: 12px;
  background: hsla(234, 91%, 47%, 1);
  background: linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );
}

.shareContainer {
  display: flex !important;
  align-items: center;
  gap: 0.4em;
  justify-content: center;
  background: hsla(234, 91%, 47%, 1);

  background: linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );
  // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0B21E3", endColorstr="#AD00F7", GradientType=1 );
  margin-left: 1vh;
  color: white;
  border-radius: 5px;
  padding: 0.5em;
  font-size: 12px;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  height: 3.8vh;
}
.shareContainer:hover {
  cursor: pointer;
}

label {
  cursor: pointer;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + span:before {
  width: 25px;
  height: 25px;
  border: 1.5px solid #7d3cff;
  content: "";
  display: inline-block;
  //margin: 0 16px 0 0;
  padding: 0;
  vertical-align: top;
  border-radius: 50%;
}

input[type="checkbox"]:checked + span:before {
  background-color: #7f7f7f00;
  border-color: #7d3cff;
  content: "";
  text-align: center;
  border-radius: 50%;
}

input[type="checkbox"]:focus + span::before {
  outline: 0;
}

label {
  display: inline-block;
  position: relative;
  padding-left: 30px; /* adjust this value based on the size of your checkbox */
  cursor: pointer;
}

label input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

label span {
  position: absolute;
  top: -18px;
  left: 0;
  height: 20px; /* adjust this value based on the size of your checkbox */
  width: 20px; /* adjust this value based on the size of your checkbox */
  border-radius: 3px;
}

label span::after {
  content: "";
  position: absolute;
  display: none;
}

label input[type="checkbox"]:checked + span::after {
  display: block;
  left: 10px; /* adjust this value to position the checkmark */
  top: 7px; /* adjust this value to position the checkmark */
  width: 5px; /* adjust this value to change the size of the checkmark */
  height: 10px; /* adjust this value to change the size of the checkmark */
  border: solid #7d3cff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
