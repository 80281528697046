.login-page,
.register-page {
  .pageContainer {
    background: rgba(0, 0, 0, 0.65);
    box-shadow: 0 8px 32px 0 rgba(27, 35, 138, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    width: min(80vw, 600px);
    height: 70vh !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    margin-bottom: 5vh;

    input {
      // width: 80% !important;
      height: 2.5rem !important;
    }

    & > h1 {
      text-align: center;
    }

    & > * {
      width: min(80%, 300px);
    }

    .form {
      & > * {
        width: 100% !important;
      }
    }
  }

  .page-header {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    flex-direction: column;
  }

  .container {
    padding-top: 8vh !important;
  }

  .header {
    color: white;
  }

  .link {
    font-size: 14px !important;
  }

  .googleBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    border: none;
    outline: none;
    background-color: white;
    border-radius: 5px;
    padding: 1rem 0rem;
  }

  .googleIcon {
    width: 1.5rem;
  }

  .alignment {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 19vw;
    flex-direction: column;
    font-size: 1rem !important;
  }

  .loginBtn {
    border-radius: 5px;
    width: 20vw !important;
    height: 6vh;
    border: none;
    margin-bottom: 2vh;
    background: hsla(234, 91%, 47%, 1);

    background: linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    );

    background: -moz-linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    );

    background: -webkit-linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    );

    // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0B21E3", endColorstr="#AD00F7", GradientType=1 );
    color: white;
    margin-top: 2vh;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .lineContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 25vw;
    color: #aeaeae;
  }
  .line {
    width: 30vw;
    border: 1px solid #aeaeae;
    height: 1px;
    margin: 10px;
  }

  .footer-link {
    color: rgb(95, 95, 237);
  }

  @media screen and (max-width: 430px) {
    .page-header {
      display: flex !important;
      align-items: center !important;
      position: relative;
      justify-content: space-around !important;
    }
    .pageContainer {
      background: rgba(0, 0, 0, 0.65);
      box-shadow: 0 8px 32px 0 rgba(27, 35, 138, 0.37);
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      width: 95vw;
      height: 70vh !important;
    }

    .text {
      margin-right: 6vw;
    }
    .header {
      color: white;
      font-size: 2rem;
    }

    .googleBtn {
      border: none;
      font-size: 1em;
      padding: 1rem !important;
      outline: none;
      background-color: white;
    }

    .googleIcon {
      width: 1.5rem;
      margin-right: 2vw;
    }

    .alignment {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 65vw;
      flex-direction: column;
      font-size: 1rem !important;
    }

    .loginBtn {
      border-radius: 5px;
      width: 65vw !important;
      margin-bottom: 1vh !important;
    }
  }
}
