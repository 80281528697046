

.dashboard-page {
  .page {
    display: grid;
    gap: 2em;
    grid-template-columns: 1fr;
    // overflow-x: auto;
    padding: 0.5rem;

    .content-center {

      .tool-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 2rem;
        padding: 0;

          .card-mobile {
            flex-direction: column;
            align-items: flex-start;

            @media(max-width: 579px) {
              align-items: center;
              justify-content: center;
              padding: 0;
            }
          }
        
          .card-image {
            width: 8rem;
            height: 8rem;
          }
        
          .card-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @media(max-width: 579px) {
              display: block;
              padding: 1px;
              align-items: center;
              justify-content: center;
              max-height: fit-content;
              text-align: center;
              margin-bottom : .6rem;
            }
          }
        
          @media(max-width: 579px) {
            .card-image {
              width: 7rem;
              height: 7rem;
              margin-bottom: .2rem;
            }

            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            gap: 0.7rem;
          }

          @media(max-width: 464px) {

            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
            gap: 0.7rem;
          }
     
        .card {
          position: relative;
          cursor: pointer;
          transition: all 0.15s ease-in-out;
          overflow: hidden;
          background: none;
          // box-shadow: 6px 6px 16px 0 rgba(0,0,0,.51), -6px -6px 16px 0 rgba(255,255,255,.05);
          
          * {
            z-index: 2;
          }

          &:hover {
            transform: scale(1.01);
            color: white;
          }

          &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(107.62deg, rgba(30, 132, 227) 0% 50%, rgba(92, 38, 211) 70% 100%);
            background-size: 300% 300%;
            background-position: 0% 0%;
            transition: all 0.3s ease;
            opacity: 0.2;
            z-index: 1;
          }

          &:hover::after {
            opacity: 0.8;
            background-position: 70% 70%;
          }
        }
    
        .bookmark {
          position: absolute;
          top: 1rem;
          right: 1rem;
          font-size: 1.2rem;
          cursor: pointer;
        }
      }
    }
    
    .right-sidebar {
      display: none;
    }
  }  
}

@media(min-width: 450px) {
  .dashboard-page .page {
    padding: 1.5rem;
  }
  .mobile-start-btn {
    display: none !important;
  }
}

@media(min-width: 1300px) {
  .dashboard-page {
    .page {
      grid-template-columns: 1fr 350px;
  
      .right-sidebar {
        display: block;
      }
    }
  }
}
