.glassContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border: 1px solid white;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.55);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 2rem;
}

.continueBtn {
  border-radius: 5px;
  width: 20vw !important;
  height: 6vh;
  border: none;
  margin-bottom: 2vh;
  background: hsla(234, 91%, 47%, 1);

  background: linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  color: white;
  margin-top: 2vh;
}

.input-lg {
  width: 20vw !important;
}

@media screen and (max-width: 450px) {
  .continueBtn {
    border-radius: 5px;
    width: 65vw !important;
    margin-bottom: 1vh !important;
  }
}
