.layout {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;

  .logo {
    font-size: 1.2rem;
  }

  .logclick:hover {
    cursor: pointer;
  }

  .cta-btn {
    margin: 0;
    width: 100%;
    font-size: 1.2rem;
  }
  .gradBtn {
    background: linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    );
    cursor: pointer;
    background: -moz-linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    );

    background: -webkit-linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    );
  }
  .btn-primary:hover {
    cursor: pointer;
    background: linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    ) !important;

    background: -moz-linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    ) !important;

    background: -webkit-linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    ) !important;
  }
  .burger-menu {
    font-size: 1.5rem;
    height: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    color: $secondary;
  }

  .sidebar-close {
    cursor: pointer;
    position: absolute;
    background-color: transparent;
    color: $secondary;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    padding: 2rem;
    padding-top: 14px;
    width: $sidebar-width;
    min-width: $sidebar-width;
    max-width: $sidebar-width;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    height: 100vh;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    box-sizing: border-box;
    z-index: 100;
    background: rgba(0, 0, 0);
    box-shadow: 0 8px 50px 0 rgba(0, 18, 255, 0.43);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 0 10px 10px 0;
    border: 1px solid transparent;
    border-right-color: rgba(255, 255, 255, 0.18);

    &.open {
      transform: translateX(0);
    }

    .logo {
      padding: 2rem 0 4rem 0;
    }

    .nav {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      margin: 2rem 0;
      margin-left: -1rem;
      position: relative;
      // width: calc(100% + 2rem);
      .nav-link {
        padding: 0;
        margin-bottom: 0.5rem;
      }
      .nav-link a {
        font-size: 1.1rem;
        padding: 0.5rem 1rem;
        border-radius: 0.4rem;
        color: $light;
        gap: 1.5vw;
        display: flex;
        align-items: center;

        &:hover,
        &.active {
          background: linear-gradient(
            135deg,
            hsla(234, 91%, 47%, 1) 34%,
            hsla(282, 100%, 48%, 1) 100%
          );

          background: -moz-linear-gradient(
            135deg,
            hsla(234, 91%, 47%, 1) 34%,
            hsla(282, 100%, 48%, 1) 100%
          );

          background: -webkit-linear-gradient(
            135deg,
            hsla(234, 91%, 47%, 1) 34%,
            hsla(282, 100%, 48%, 1) 100%
          );
          background-size: 210% 210%;
          background-position: top right;
        }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    padding: 1.5rem 1rem;
    background: #0e0d0d;
    position: -webkit-sticky;
    position: sticky;
    justify-content: space-between;
    width: calc(100vw - $sidebar-width - 17.5px);
    z-index: 90;
    transition: background-color 0.3s ease;
    top: 0;
  
    @media screen and (max-width: 800px) {
      width: 100%;
    }
    
    .main-nav {
      margin-left: auto;
      display: flex;
      gap: 1.5rem;
      padding-right: 2vw;
      a {
        color: $secondary;
        transition: filter 0.3s ease;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .user-img {
        width: 2rem;
        height: 2rem;
        min-width: 2rem;
        border-radius: 100px;
        margin: 0;
        padding: 0;
      }
    }
    .page-title {
      margin: 0;
      margin-left: 2rem;
      font-size: 1.8rem;
      margin-top: -0.15rem;
      display: none;
    }
  }

  .navShareContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: hsla(234, 91%, 47%, 1);

    background: linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    );

    background: -moz-linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    );

    background: -webkit-linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    );

    margin-left: 1vh;
    color: white;
    border-radius: 5px;
    width: fit-content;
    padding: 0.5em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    height: 3.3vh;
    .icon {
      font-size: 2vh;
    }
  }

  .navShareContainer:hover {
    cursor: pointer;
  }

  .senseiContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    left: 0;
    // transform: translateX(-50%);
    user-select: none;
    pointer-events: none;

    .sensei-projects {
      align-self: flex-start;
      width: 100%;
    }
  }

  .sensei {
    height: 4rem;
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
    // margin-left: 1.5rem;
    border-radius: 50%;
  }
  .sensei-text {
    color: #fff;
    margin-left: 1vw;
    font-size: 1.5rem;
  }
  .share-icon {
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
    // padding-right: 2rem;
    margin-left: auto;
  }

  .main-content {
    position: relative;
    overflow-y: auto;
    height: 100vh;
    background-color: #000;
  }
}

@media screen and (max-width: 450px) {
  .layout {
    -webkit-overflow-scrolling: touch !important;
    overflow: auto !important;
    .header {
      height: 8vh;
    }
    .nav {
      .nav-link a {
        gap: 5vw !important;
      }
    }
    .networkContainer {
      background: #f6c915;
      color: black;
      text-align: center;
      width: 100vw;
      height: 5vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    // .senseiContainer {
     
    // }
    .sidebar {
      position: fixed;
      top: 0;
      left: 0;
      padding: 2rem;
      padding-top: 14px;
      min-width: 70vw;
      width: 70vw;
      max-width: 70vw;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100vh;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      overflow-y: auto;
      box-sizing: border-box;
      z-index: 100;
      background: rgba(0, 0, 0);
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
      border-radius: 0 10px 10px 0;
      border: 1px solid transparent;
      border-right-color: rgba(255, 255, 255, 0.18);
      box-shadow: none;
    }
  }
}

@media (min-width: 800px) {
  .layout {
    display: flex;

    .sidebar {
      position: relative;
      transform: translateX(0);
      border: none;

      .logo {
        padding-top: 0;
      }
    }
    .networkContainer {
      background: #f6c915;
      color: black;
      text-align: center;
      width: 82vw;
      height: 5vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .main-content {
      flex-grow: 1;

      .header .page-title {
        margin-left: 0;
        display: block;
      }

      .header .top-header {
        display: none;
      }
    }

    .burger-menu {
      display: none;
    }
  }
}
