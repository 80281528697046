.cta-btn {
  background: hsla(234, 91%, 47%, 1);

  background: linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );
}
.cta-btn:hover, .cta-btn:focus {
  cursor: pointer;
  background: hsla(234, 91%, 47%, 1) !important;

  background: linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  ) !important;

  background: -moz-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  ) !important;

  background: -webkit-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  ) !important;
  color: white !important;
}

.iconContainer {
  display: flex;
  align-items: center;
  margin-right: 1vw;
  width: 11vw;
  justify-content: space-between;
}
