.info-card + .info-card {
  margin-top: 1rem;
}

.info-card .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.info-card .content {
  display: flex;
  align-items: center;
}

.custom-height-modal {
  height: 100vh !important;
}

.modal.modal-black .modal-content{
  background: linear-gradient(107.62deg, rgba(30, 132, 227, 1) 0%, rgba(92, 38, 211, 1) 100%);
    color: rgba(255, 255, 255, 0.8);
}

.modal.modal-dark-blue .modal-content{
  background: rgb(27, 83, 194);
  background: linear-gradient(158deg, rgba(27, 83, 194, 1) 0%, rgba(36, 11, 97, 1) 65%, rgba(78, 6, 255, 1) 100%);
  background: -moz-linear-gradient(158deg, rgba(27, 83, 194, 1) 0%, rgba(36, 11, 97, 1) 65%, rgba(78, 6, 255, 1) 100%);
  background: -webkit-linear-gradient(158deg, rgba(27, 83, 194, 1) 0%, rgba(36, 11, 97, 1) 65%, rgba(78, 6, 255, 1) 100%);
  color: rgba(255, 255, 255, 0.8);
}