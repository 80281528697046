.chat-page {
  .chat_page_container_ll {
    height: fit-content;
    // padding-bottom: 100px;
  }

  @media screen and (max-width: 768px) {
    .chat_page_container_ll {
      margin-top: 0px;
      margin-bottom:5rem;
    }
  }
  .input_box_reference_ll {
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 0px;
    height: fit-content;
    display: flex;
  }

  .sign_out_modal_ll {
    position: fixed;
    width: 500px;
    height: 300px;
    left: 0;
    border: 1px solid white;
    border-radius: 20px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    background-color: #0d0e14;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1000000000000000000000000000000000000000000000000;
    padding: 20px;
  }
  @media screen and (max-width: 700px) {
    .sign_out_modal_ll {
      width: 90vw;
      height: 30vh;
      top: 30%;
    }
  }
  .sign_out_modal_ll button:nth-child(1) {
    background-color: red;
    color: white;
    padding: 10px;
    width: 60px;
    margin-right: 10px;
  }
  .sign_out_modal_ll button:nth-child(2) {
    background-color: #1f62cf;
    color: white;
    padding: 10px;
    width: 60px;
    margin-right: 10px;
  }
  .header_chatpage_ll {
    position: fixed;
    width: 100%;
    height: fit-content;
    display: flex;
    top: 0;
    display: flex;
    left: 0;
    background-color: #0d0e14;
    z-index: 100;
    padding-left: 20px;
    align-items: center;
    height: 60px;
    font-size: 2rem;
  }

  .sensei_header_ll {
    color: #c05740;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 700px) {
    .mobile_nav_logo_ll {
      display: none !important;
    }
  }
  .side_box_input_ll {
    width: 320px;
    height: 100%;
    z-index: -10000000000000000000000000000000000000000000000;
    background-color: transparent;
  }

  .message_box_ll {
    flex: 1;
    padding: 10px;
    background-color: #0d0e14;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .message_box_inner_ll {
    width: 100%;
  }
  .chat_container_ll {
    height: 100vh;
    background-color: #0d0e14;
    // padding-bottom: 120px;
  }

  .message_box_input_ll {
    background-color: #303132;
    width: 90%;
    height: fit-content;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 6vh;
    padding-left: 1vw;
    margin-bottom: 3vh;
    color: white;
  }

  .message_input_ll {
    width: 80%;
    font-size: 16px;
  }

  .hambuger_ll {
    display: none;
    cursor: pointer;
  }
  .mobile_nav {
    display: none;
  }

  .backToTop_btn_ll {
    position: fixed;
    bottom: 100px;
    right: 50px;
    height: 30px;
    width: 30px;
    background-color: #0d0e14;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-self: center;
    color: white;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 700px) {
    .message_input_ll {
      width: 70%;
    }
    .hambuger_ll {
      font-size: 2rem;
      display: inline-block;
    }
    .mobile_nav {
      position: fixed;
      top: 0;
      width: 100vw;
      height: 70px;
      padding: 10px;
      display: block;
      background-color: #0d0e14;
      z-index: 1000;
      display: flex;
      justify-content: space-between;
    }
    .mobile_nav_logo_ll {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .side_box_input_ll {
      display: none;
    }
    .message_box_ll {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .message_box_input_ll {
      width: 100%;
    }
    .chat_page_container_ll {
      padding-bottom: 0px;
    }
  }

  @media screen and (max-width: 390px) {
    .message_box_ll {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 12px;
    }
    .message_box_input_ll {
      width: 95vw;
      margin-right: 0px;
      margin-left: 0px;
    }
  }

  @media screen and (max-width: 280px) {
    .message_box_input_ll {
      width: 98vw;
      margin-left: 0px;
    }
  }

  .messages-container {
    // padding-bottom: 4rem;
    margin-top: 3vh;
    &::-webkit-scrollbar {
        width: 1px;
        background-color: transparent;
      }
    
    &::-moz-scrollbar-scrollbar {
      width: 1px;
      background-color: transparent;
    }
    /* overflow-y: scroll; */
  }

  .chat-details {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    .messages-img {
      height: 200px;
    }
  }
}
