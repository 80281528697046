.account_container_ll{
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  /* margin-left: 320px; */
  position: relative;
  height: 100vh;
}

.account_inner_ll{
  width: 100%;
  margin: 0 auto;
  margin-top: -100px;    
}
@media screen and (max-width:768px) {
  .account_inner_ll{
      margin-top: -50px;
  }
}

.account_main_ll{
  width: 100%;
  height: 100vh;
}

.account_container_ll .sidenav_middle_ll{
  /* display: none; */
  visibility: hidden;
}

.account_container_ll .btn-round{
  border-radius: 5px;
}

@media screen and (max-width:700px) {
  .account_container_ll{
      margin-left: 0px;
  }
}

@media screen and (max-width:768px) {
  .user_details_ll{
      margin-top: -200px;
  }
}