.btn,
.btn-lg {
  height: 52px;
  border-radius: 4px;
  padding: 0 20px !important;
  line-height: 20px !important;
  font-weight: 500;
  min-width: 87px;
  max-width: 218px;
}

.btn-round {
  padding: 5px 15px !important;
  border-width: 1px;
  border-radius: 30px;
  max-width: 100% !important;
  height: 58px !important;
  line-height: 1.35 !important;
}

a.btn {
  align-items: center;
  display: flex !important;
  justify-content: center;
}

.btn {
  &-icon-single {
    width: 132px !important;
    &-left {
      i {
        padding-right: 8px;
      }
    }
    &-right {
      i {
        padding-left: 8px;
      }
    }
  }
  &-icon-double {
    i {
      &:first-child {
        padding-right: 8px;
      }
      &:nth-child(2) {
        padding-left: 8px;
      }
    }
    width: 160px !important;
  }
  &-sm {
    height: 44px;
    width: 100px;
  }
}

.pulse {
  display: inline;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
  animation: pulse 2s infinite;
  padding: 15px 18px;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
