/*!

 =========================================================
 * BLK Design System PRO React - v1.2.1 based on BLK Design System PRO - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
 * Copyright 2022 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import "blk-design-system-pro/custom/functions";
@import "~bootstrap/scss/functions";

@import "blk-design-system-pro/variables";
@import "~bootstrap/scss/variables";

@import "blk-design-system-pro/custom/mixins";
@import "~bootstrap/scss/mixins";

// Bootstrap components

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Custom components

@import "blk-design-system-pro/custom/alerts.scss";
@import "blk-design-system-pro/custom/buttons.scss";
@import "blk-design-system-pro/custom/dropdown.scss";
@import "blk-design-system-pro/custom/footer.scss";
@import "blk-design-system-pro/custom/forms.scss";
@import "blk-design-system-pro/custom/images.scss";
@import "blk-design-system-pro/custom/input.scss";
@import "blk-design-system-pro/custom/modal.scss";
@import "blk-design-system-pro/custom/navbar.scss";
@import "blk-design-system-pro/custom/type.scss";
@import "blk-design-system-pro/custom/icons.scss";
@import "blk-design-system-pro/custom/checkboxes-radio.scss";
@import "blk-design-system-pro/custom/fixed-plugin.scss";
@import "blk-design-system-pro/custom/pagination.scss";
@import "blk-design-system-pro/custom/message.scss";
@import "blk-design-system-pro/custom/misc.scss";
@import "blk-design-system-pro/custom/rtl.scss";
@import "blk-design-system-pro/custom/input-group.scss";
@import "blk-design-system-pro/custom/progress.scss";
@import "blk-design-system-pro/custom/badge.scss";
@import "blk-design-system-pro/custom/pills.scss";
@import "blk-design-system-pro/custom/tabs.scss";
@import "blk-design-system-pro/info-areas.scss";
@import "blk-design-system-pro/misc-extend.scss";
@import "blk-design-system-pro/sections-extend.scss";
@import "blk-design-system-pro/footers.scss";
@import "blk-design-system-pro/tables.scss";
@import "blk-design-system-pro/custom/chat-onboarding";

// Pro components

@import "blk-design-system-pro/example-pages.scss";
@import "blk-design-system-pro/social-buttons.scss";
@import "blk-design-system-pro/layout.scss";
@import "blk-design-system-pro/media.scss";
@import "blk-design-system-pro/modals-extend.scss";
@import "blk-design-system-pro/navbar-extend.scss";
@import "blk-design-system-pro/buttons-extend.scss";
@import "blk-design-system-pro/pills-extend.scss";
@import "blk-design-system-pro/dropdown-extend.scss";

// Sections
@import "blk-design-system-pro/custom/sections.scss";

// Vendor / Plugins

@import "blk-design-system-pro/custom/vendor/plugin-perfect-scrollbar.scss";
@import "blk-design-system-pro/custom/vendor/plugin-animate-bootstrap-notify.scss";
@import "blk-design-system-pro/custom/vendor/plugin-bootstrap-switch.scss";
@import "blk-design-system-pro/custom/vendor/plugin-nouislider.scss";
@import "blk-design-system-pro/custom/vendor/plugin-datetimepicker.scss";
@import "blk-design-system-pro/plugins/plugin-tagsinput.scss";
@import "blk-design-system-pro/plugins/plugin-bootstrap-select";
@import "blk-design-system-pro/plugins/plugin-jasny-fileupload.scss";
@import "blk-design-system-pro/plugins/plugin-carousel-slick.scss";

// Cards

@import "blk-design-system-pro/custom/card";
@import "blk-design-system-pro/custom/cards/card-chart";
@import "blk-design-system-pro/custom/cards/card-map";
@import "blk-design-system-pro/custom/cards/card-user";
@import "blk-design-system-pro/custom/cards/card-task";
@import "blk-design-system-pro/custom/cards/card-plain";
@import "blk-design-system-pro/custom/cards/card-register";
@import "blk-design-system-pro/custom/cards/card-stats";
@import "blk-design-system-pro/custom/cards/card-chart";
@import "blk-design-system-pro/cards/card-background";
@import "blk-design-system-pro/cards/card-blog";
@import "blk-design-system-pro/cards/card-profile";
@import "blk-design-system-pro/cards/card-pricing";
@import "blk-design-system-pro/cards/card-login";
@import "blk-design-system-pro/cards/card-testimonials";
@import "blk-design-system-pro/cards/card-product";

// Avatars

@import "blk-design-system-pro/avatars/avatar-group";
@import "blk-design-system-pro/avatars/avatar";

// Responsive

@import "blk-design-system-pro/responsive";

// React Differences
@import "blk-design-system-pro/react/react-differences";


// Pages
@import "blk-design-system-pro/pages/login";
@import "blk-design-system-pro/pages/account";
@import "blk-design-system-pro/pages/dashboard";
@import "blk-design-system-pro/pages/chat";