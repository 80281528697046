// Style for Contact Us page-header

.contact-page {
  .page-header {
    height: 400px;
    min-height: 400px;
    margin-bottom: 50px;
    .container {
      padding-top: 8vh;
    }
  }
  form {
    margin-bottom: 100px;
  }
  .infos {
    border-bottom: 1px solid;
    padding-bottom: 50px;
    [class*="col-"] {
      border-right: 1px solid;
      &:last-child {
        border: none;
      }
    }
  }

  .title {
    margin: 15px 0;
  }
}

.ecommerce-page {
  .page-header .title {
    font-size: 5em;
  }

  .card-background.card-blog[data-animation="true"]:after,
  .card-background.card-blog[data-animation="zooming"]:after {
    height: 100%;
  }

  .page-header {
    min-height: 95vh;
  }

  .carousel-team .carousel-control-next,
  .carousel-team .carousel-control-prev {
    z-index: 2;
    cursor: pointer;
  }
}

// Style for the About Us page

.about-us {
  .path {
    position: absolute;
    opacity: 0.02;
  }
  .page-header {
    .path3 {
      height: 380px;
      right: 20%;
      top: 33%;
      transform: rotate(-85deg);
    }
  }

  .about-description {
    .path4 {
      left: 9%;
      top: 0;
      width: 77%;
    }
  }

  .about-pricing {
    .description {
      margin-bottom: 25px;
    }
  }
}

// Style for the Pricing page

.pricing {
  .pricing-2.project-raised {
    padding-top: 0;
    margin-top: -10vh;

    [class*="col-"] {
      padding-left: 0;
      padding-right: 0;

      @media screen and (min-width: 991px) {
        &:nth-of-type(2) {
          transform: scale(1.1);
          z-index: 1;
        }
      }
    }
  }
  .page-header-image {
    background-position: center top !important;
  }
}

// Style for the Blog Post page

.blog-post {
  .page-header {
    .author {
      margin-top: 100px;
    }
  }

  .slick-slide {
    display: none;
    float: left;
    margin: 0 10px;
    margin-top: -20px;
    min-height: 1px;
    transition: all 300ms ease 0s;

    img {
      width: 100% !important;
      margin-bottom: 30px;
    }
  }
}

// Style for the Blog Discovery page

.blog-posts {
  .page-header-small {
    min-height: 70vh !important;
  }

  .main-raised {
    margin-top: -100px;
  }
}

// style for the landing page
.index-page {
  .page-header {
    height: 100vh;

    .container {
      > .content-center {
        &.brand {
          .h1-seo,
          h3 {
            color: $white;
            font-weight: 600;
            text-transform: capitalize;
            span {
              font-weight: $font-weight-bold;
            }
          }

          .h1-seo {
            font-size: 5em;
          }
        }
      }
    }

    .category-absolute {
      position: absolute;
      top: 100vh;
      margin-top: -60px;
      padding: 0 15px;
      width: 100%;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .card-coins {
    padding-top: 100px;
  }

  .card {
    &.card-background[data-animation="true"] {
      height: $card-background-height-sm;
    }
  }

  .section-navbars .navbar {
    margin-bottom: 20px;
  }
}

.index-page,
.login-page,
.reset-page {
  .squares {
    animation: move-left-right 1s infinite;
    @include linear-gradient($primary-states, $primary);
    position: absolute;
    transition: 0.5s ease-out;
    overflow: hidden;
    border-radius: 20%;
    &.square1 {
      animation: move-left-right 4s infinite;
      height: 300px;
      width: 300px;
      opacity: 0.5;
      left: 3%;
      top: -21%;
    }

    &.square2 {
      animation: move-left-right 6s infinite;
      height: 400px;
      width: 400px;
      opacity: 0.4;
      right: -5%;
      top: -12%;
    }

    &.square3 {
      animation: move-left-right 5s infinite;
      height: 200px;
      width: 200px;
      opacity: 0.1;
      left: -5%;
      bottom: 0%;
    }

    &.square4 {
      animation: move-left-right 10s infinite;
      height: 100px;
      width: 100px;
      opacity: 0.9;
      right: 27%;
      top: 70%;
    }

    &.square5 {
      animation: move-left-right 6s infinite;
      height: 250px;
      width: 250px;
      opacity: 0.1;
      left: 32%;
      bottom: 29%;
    }

    &.square6 {
      animation: move-left-right 9s infinite;
      left: 10%;
      top: 35%;
      height: 80px;
      width: 80px;
      opacity: 0.8;
    }

    &.square7 {
      animation: move-left-right 3s infinite;
      width: 300px;
      height: 300px;
      right: -5%;
      bottom: 0%;
      opacity: 0.1;
    }
  }
}

.landing-page {
  overflow-x: hidden;

  .page-header {
    &::after {
      background: radial-gradient(
        ellipse at top right,
        #292d61 23%,
        #171941 65%
      );
      position: absolute;
      height: 100vh;
      width: 100%;
      opacity: 0.5;
    }
    .path {
      max-width: 65%;
      right: 0;
      top: 30px;
    }

    .path2 {
      position: absolute;
      opacity: 0.02;
      max-width: 41%;
      right: -60px;
      top: -50px;
    }

    .squares {
      top: 50%;
      right: 18%;
      max-height: 200px;
    }

    .wave {
      top: 45%;
      right: 30%;
      max-height: 90px;
    }

    .circle {
      top: 20%;
      right: 36%;
      max-height: 75px;
    }

    .triangle {
      top: 35%;
      left: 5%;
      max-height: 200px;
    }
  }

  .section:first-of-type {
    .path {
      left: -110px;
      max-width: 60%;
      top: -50px;
    }
  }
  .section:nth-of-type(2) {
    .path {
      left: auto;
      right: -250px;
      max-width: 70%;
      top: 0;
    }
    .path2 {
      position: absolute;
      opacity: 0.02;
      right: auto;
      left: 420px;
      max-width: 10%;
      top: 420px;
    }

    .path3 {
      position: absolute;
      opacity: 0.02;
      left: auto;
      right: 500px;
      max-width: 10%;
      top: -90px;
    }
  }
  .section:nth-of-type(3) {
    padding-top: 200px;

    .path {
      right: auto;
      left: 50px;
      max-width: 45%;
      top: 60px;
    }
  }
  .section:nth-of-type(4) {
    .path {
      max-width: 60%;
      right: 3%;
      top: 15%;
    }
    .path2 {
      position: absolute;
      opacity: 0.02;
      left: 25%;
      right: auto;
      max-width: 10%;
      top: 100px;
    }
  }
  .section:nth-of-type(5) {
    .path {
      max-width: 50%;
      left: 5%;
      top: 3%;
    }
  }

  .header {
    height: 100vh;
    position: relative;

    .container {
      padding-top: 26vh;
      color: $white;
      z-index: 2;
      position: relative;
    }

    .share {
      margin-top: 150px;
    }
    h1 {
      font-weight: 600;
    }
    .title {
      color: $white;
    }
  }

  .section-team {
    .team .team-player img {
      max-width: 100px;
    }

    .team-player {
      margin-bottom: 15px;
    }
  }

  .section-contact-us {
    .title {
      margin-bottom: 15px;
    }

    .description {
      margin-bottom: 30px;
    }

    .input-group,
    .send-button,
    .textarea-container {
      padding: 0 40px;
    }

    .textarea-container {
      margin: 40px 0;
    }

    a.btn {
      margin-top: 35px;
    }
  }

  .section-safe {
    .icon {
      i {
        font-size: 21px;
      }
    }
  }

  .section-lg {
    overflow-x: hidden;
  }
}

// style for the profile page
.profile-page {
  overflow-x: hidden;

  .page-header {
    min-height: 550px;

    .container {
      padding-top: 30vh;
    }

    .path {
      max-width: 65%;
      right: 0;
      top: 100px;
    }
  }

  .info {
    padding: 40px 0 0;

    .description {
      text-align: left;
      padding-left: 20px;
    }
  }

  .profile-title {
    position: absolute;
    top: 110px;
    left: 60px;
  }

  .profile-description {
    margin-top: -30px;
    max-width: 450px;
    font-size: $font-size-base;
    line-height: 26px;
  }

  .profile-container {
    color: $white;
  }
  .photo-container {
    max-width: 400px;
    overflow: hidden;
    -webkit-box-shadow: -15px 20px 60px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -15px 20px 60px 0px rgba(0, 0, 0, 0.3);
    box-shadow: -15px 20px 60px 0px rgba(0, 0, 0, 0.3);
  }

  .title {
    text-align: center;
    margin-top: 30px;
  }

  .description,
  .category {
    text-align: center;
  }

  h5.description {
    max-width: 700px;
    margin: 20px auto 75px;
  }

  .nav-align-center {
    margin-top: 30px;
  }

  .content {
    .social-description {
      display: inline-block;
      max-width: 150px;
      width: 145px;
      text-align: center;
      margin: 15px 0 0px;

      h2 {
        margin-bottom: 15px;
      }
    }
  }

  .collections {
    img {
      margin-bottom: 30px;
    }
  }

  .gallery {
    margin-top: 45px;
    padding-bottom: 50px;
  }

  .card-coin {
    .list-group {
      margin: 0 !important;
      text-align: left !important;
      color: $opacity-5;
    }
  }
  .card {
    .card-header {
      .profile-title {
        top: 125px;
      }
    }
  }
}

.section-full-page {
  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:before {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &[data-image]:after {
    opacity: 0.5;
  }

  > .content,
  > .footer {
    position: relative;
    z-index: 4;
  }

  > .content {
    min-height: calc(100vh - 80px);
  }

  .full-page-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  .footer nav > ul a:not(.btn),
  .footer,
  .footer .copyright a {
    color: $white;
  }
}

.presentation-page {
  .page-header {
    height: 100vh;

    .h1-seo {
      font-size: 5em;
    }

    &::after {
      background: radial-gradient(
        ellipse at top right,
        #292d61 23%,
        #171941 65%
      );
      // content: "";
      position: absolute;
      height: 100vh;
      width: 100%;
      opacity: 0.5;
    }
    .path {
      max-width: 65%;
      right: 0;
      top: 30px;
    }

    .path2 {
      position: absolute;
      opacity: 0.02;
      max-width: 41%;
      right: -60px;
      top: -50px;
    }

    .squares {
      top: 50%;
      right: 18%;
      max-height: 200px;
    }

    .wave {
      top: 45%;
      right: 30%;
      max-height: 90px;
    }

    .circle {
      top: 20%;
      right: 36%;
      max-height: 75px;
    }

    .triangle {
      top: 35%;
      left: 5%;
      max-height: 200px;
    }
  }

  .slick-slider .slick-arrow {
    position: absolute;
    top: 45%;
    left: 25%;
  }

  .slick-slider .btn.slick-next {
    margin-left: 50px;
  }

  .slick-initialized .slick-slide {
    opacity: 0.05;
    transition: 0.2s ease;
  }

  .slick-slide.slick-active {
    opacity: 0.05;
  }

  .slick-slide.slick-current.slick-active.slick-center {
    opacity: 1;
  }

  .section-content img {
    border: 1px solid $default;
  }

  .section-free-demo {
    .card-pricing {
      text-align: left;
      ul {
        margin: 0;
        li {
          text-align: left;
        }
      }
    }
  }
  .carousel-control-next,
  .carousel-control-prev {
    margin-top: 220px;
    font-size: 30px;
  }

  .card-pricing ul li b {
    color: #fff;
  }

  .section-pricing {
    .card-pricing ul.b-left {
      &:after {
        position: absolute;
        height: 90%;
        top: 10%;
        right: 104%;
        border-radius: 10px;
        content: "";
        width: 2px;
        background: $primary;
      }
      li {
        &:after {
          content: "";
          position: absolute;
          height: 15px;
          width: 15px;
          background: red;
          right: 103%;
          transform: translate(20%);
          border-radius: 50%;
          z-index: 2;
          border: 2px solid $primary;
          background: $body-bg;
        }
      }
    }
  }

  .section-examples {
    img {
      border: 1px solid $default;
    }
  }

  .features-7 {
    overflow: hidden;
    img {
      max-width: 125%;
    }
  }

  .section-sections {
    img {
      border: 1px solid $default;
      margin-top: 20px;
    }
  }
}

// Style for the Register Page

.register-page {
  background-color: $background-black;

  .link {
    font-size: 10px;
    color: $white;
    text-decoration: none;
  }

  .register-bg {
    height: 700px;
    background: linear-gradient(
      to bottom,
      $transparent-bg 0%,
      $card-black-background 100%
    );
    width: 700px;
    position: relative;
    right: -600px;
    top: -750px;
    border-radius: 20%;
    transform: rotate(-15deg);
  }

  .square {
    position: absolute;
    transition: 0.5s ease-out;
    border-radius: 2px;
    background-size: 100%;
    border-radius: 20%;
  }

  .square-1 {
    height: 300px;
    width: 300px;
    background-image: url("~assets/img/square1.png");
    top: 175px;
    right: 150px;
    opacity: 0.8;
  }

  .square-2 {
    height: 120px;
    width: 120px;
    background-image: url("~assets/img/square2.png");
    opacity: 0.9;
    top: 310px;
    right: 390px;
  }

  .square-3 {
    width: 200px;
    height: 200px;
    background-image: url("~assets/img/square3.png");
    opacity: 0.3;
    top: -50px;
    right: 400px;
  }

  .square-4 {
    height: 200px;
    width: 200px;
    background-image: url("~assets/img/square4.png");
    right: -50px;
    top: 525px;
  }

  .square-5 {
    height: 150px;
    width: 150px;
    background-image: url("~assets/img/square5.png");
    opacity: 0.5;
    top: 500px;
    right: 470px;
  }

  .square-6 {
    height: 70px;
    width: 70px;
    background-image: url("~assets/img/square5.png");
    opacity: 0.9;
    top: 105px;
    right: 20px;
  }

  .square-7 {
    height: 200px;
    width: 200px;
    background-image: url("~assets/img/square5.png");
    left: -100px;
    top: 100px;
    opacity: 0.4;
  }

  .square-8 {
    height: 100px;
    width: 100px;
    background-image: url("~assets/img/square1.png");
    left: 300px;
    top: 400px;
    opacity: 0.4;
  }
}

// Style for the Product Page

.product-page {
  .stars {
    i {
      cursor: pointer;
    }
  }
  .input-group {
    .input-number {
      border: none;
      font-size: 24px;
      max-width: 80px;
      padding: 25px 20px;
      text-align: center;
    }

    .input-group-btn {
      padding: 0;

      .btn {
        padding: 11px 13px;
      }
    }
  }
  .page-header {
    .shape {
      width: 70%;
    }
  }

  .carousel {
    .carousel-item img {
      height: 500px;
      margin: 0 auto;
    }

    .carousel-control-next {
      right: -50px;
    }
    .carousel-control-prev {
      left: -50px;
    }
  }

  @media screen and (max-width: 576px) {
    .carousel-item img {
      height: auto !important;
    }
    .carousel-control-next {
      right: 0 !important;
    }
    .carousel-control-prev {
      left: 0 !important;
    }
    .carousel-indicators {
      bottom: -3vh;
    }
  }
}

// Style for the Reset Password Page

.reset-page {
  overflow-x: hidden;

  .squares {
    @include linear-gradient($warning-states, $warning);
  }

  .card-login {
    .card-header {
      img {
        width: 450px;
        opacity: 0.5;
        margin-top: -80px;
      }

      .card-title {
        font-size: 4em;
        margin-top: 20px;
        line-height: 1.3em;
        color: $white;
      }
    }
    .input-group {
      margin: 30px 0;
    }
  }
}

// Style for the Account Settings Page

.account-settings {
  .labels {
    color: $light;
  }
  .nav {
    .nav-link {
      font-size: $font-size-lg;
      color: $white;
      padding-left: 0;

      &.active {
        color: $primary;
      }

      i {
        margin-right: 5px;
      }
    }

    hr {
      width: 100%;
      height: auto;
    }
  }

  .table {
    .avatar {
      background: transparent;
      border-radius: 0;
    }
    .form-check.form-check-radio {
      margin-bottom: 20px;
    }
  }
}

// Style for the Invoice Page

.invoice-page {
  .table {
    tr td,
    tr,
    th {
      color: $white !important;
    }
  }
  .btn-print {
    margin-left: 30px;
  }

  .card-invoice {
    .card-footer {
      padding: 15px;
    }
  }
}

// Style for the Invoice Page

.checkout-page {
  .code-validate {
    .input-group {
      .form-control {
        height: auto;
      }
      .input-group-append .btn {
        margin: 0;
      }
    }
  }
  .btn-group {
    padding-left: 0;
  }

  .tab-content {
    min-height: 270px;
    max-height: 270px;
  }

  .labels {
    color: $light;
  }
}

// Style for the Chat Page

.chat-page {
  @media screen and (min-width: 991px) {
    .section {
      min-height: 100vh;
      max-height: 999px;
    }
  }

  .path {
    width: 70%;
    transform: rotate(90deg);
  }

  .list-group {
    .list-group-item:not(.active) {
      background: $transparent-bg;
    }

    .avatar {
      width: 40px;
      height: 40px;
    }

    .badge {
      padding: 4px;
      margin-top: 3px;
      display: inline-block;
      float: right;
    }

    .list-group-item {
      &.active {
        border-radius: $border-radius-lg;
      }

      &:nth-of-type(2) {
        border-top: 0;
      }
    }
  }

  .card {
    max-height: 70vh;
    overflow: auto;

    .card-header,
    .card-footer {
      background: $transparent-bg;
      display: flex;
    }

    .far {
      margin-right: 3px;
    }
  }

  .spinner {
    width: 70px;
    text-align: center;
    display: flex; /* Ensures centering */
    justify-content: center;
    align-items: center;
    font-size: 0;

    > div {
      width: 7px;
      height: 7px;
      background-color: $gray-300;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

.about-us,
.account-settings,
.index-page,
.invoice-page,
.checkout-page,
.chat-page,
.landing-page,
.profile-page,
.register-page,
.reset-page,
.product-page,
.presentation-page {
  background-image: url("~assets/img/dots.png");
  background-size: contain;
}

.login-page {
  background: radial-gradient(
    circle at 50% 50%,
    rgba(42, 26, 38, 1) 0%,
    rgba(13, 14, 20, 1) 23%
  ) !important;
}

.login-page .footer {
  position: relative;
}

@include media-breakpoint-down(md) {
  .section:nth-of-type(2) {
    .path {
      left: 10% !important;
    }
    .path2 {
      left: 20% !important;
    }
  }

  .info:first-of-type {
    padding-top: 10% !important;
  }

  .presentation-page .section-free-demo .github-background-container {
    position: absolute;
    right: -100px !important;
    top: -501px !important;
  }

  .contact-page .infos [class*="col-"] {
    border-right: none;
  }

  .testimonials-1 .carousel-team .carousel-control-next {
    left: 42% !important;
  }

  .presentation-page .section-cards .card .card-footer .stats.stats-right {
    float: none;
    margin-top: 10px;
    margin-left: 10px;
  }

  .presentation-page .section-pricing .card-pricing ul.b-left:after {
    content: none;
  }
  .presentation-page .section-pricing .card-pricing ul.b-left li:after {
    content: none;
  }

  .index-page .table td .btn {
    margin-bottom: 5px;
  }
}

@include media-breakpoint-down(sm) {
  .card-login img {
    width: 70%;
  }

  .section:nth-of-type(2) {
    .path,
    .path2 {
      left: 0 !important;
    }
  }
  .section-coins {
    margin-top: 0 !important;
  }

  .section-icons {
    i {
      display: none;
    }
  }

  .contactus-3 .title {
    font-size: 4em;
  }

  .ecommerce-page .carousel-team .carousel-control-next,
  .carousel-team .carousel-control-prev {
    top: 100%;
    .tim-icons {
      border: 1px solid $white;
      border-radius: 50%;
      padding: 5px;
    }
  }

  .carousel-team {
    margin-top: 0px;
  }

  .testimonials-1 .carousel-inner img {
    margin-top: 50px;
  }

  .ecommerce-page .card-product .card-image {
    text-align: center;
  }
  .testimonials-1 .carousel-team .carousel-control-next {
    left: 47% !important;
  }

  .presentation-page .info {
    margin-top: 50px !important;
  }

  .checkout-page .tab-content {
    margin-bottom: 50px;
  }

  .about-us .carousel-team .carousel-control-prev,
  .about-us .carousel-team .carousel-control-next {
    top: 103%;
    .tim-icons {
      border: 1px solid #fff;
      border-radius: 50%;
      padding: 5px;
    }
  }

  .about-us .carousel-team .carousel-control-prev {
    left: 44% !important;
  }

  .about-us .carousel-team .carousel-control-next {
    left: 55% !important;
  }
}

@include media-breakpoint-down(xs) {
  .card-login {
    margin-top: 100px;
    img {
      width: 350px;
    }
  }

  .profile-page .page-header {
    max-height: unset;
  }

  .landing-page {
    .page-header {
      h1 {
        margin-top: 100px;
      }
    }
  }

  .ecommerce-page .card .card-image {
    text-align: center;
  }

  .ecommerce-page .card-product .btn {
    margin-top: -25px;
  }

  .presentation-page .page-header .h1-seo {
    margin-top: 150px;
  }

  .presentation-page .slick-slider .slick-arrow {
    top: -5%;
    left: 38%;
  }

  .presentation-page .section.section-sections {
    padding-top: 50px;
  }

  .presentation-page .section-pricing .our-clients {
    display: none;
  }

  .error-page > .container .title {
    font-size: 9em;
  }

  .reset-page .card-login .card-header .card-title {
    font-size: 3.5em;
  }

  .checkout-page .nav-tabs {
    padding-right: 60px;
  }

  .about-us .page-header .title {
    margin-top: 200px;
  }
}

@include media-breakpoint-up(md) {
  .info:first-of-type {
    // padding-top: 60%;
    z-index: 2;
  }
}
