.onboardingButton {
  background: hsla(234, 91%, 47%, 1);

  background: linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );
  padding: 1rem 4rem;
  outline: none;
  border: none;
  color: white;
  margin-top: 2vh;
  font-weight: 500;
  border-radius: 4px;
}

.onboardingButton:hover {
  background: hsla(234, 91%, 47%, 1) !important;

  background: linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  ) !important;

  background: -moz-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  ) !important;

  background: -webkit-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  ) !important;
}

.onboardingContainer {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.grecaptchaBadge {
  width: 4vw !important;
  height: 2vh !important;
}

.PhoneInputCountry {
  height: 5vh !important;
  border: 1px solid #5e5e5e;
  color: #888888;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 5px;
}

.PhoneInputInput {
  height: 5vh !important;
  background: transparent;
  color: white;
  border-radius: 5px;
  padding-left: 1vw;
  border: 1px solid #5e5e5e;
}
.input-group .form-control:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border: 1px solid #5e5e5e;
}
.input-group .form-control:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border: 1px solid #5e5e5e;
}

.input-lg {
  width: 10vw;
}

.continueButton {
  background: hsla(234, 91%, 47%, 1);

  background: linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );
  padding: 1rem 4rem;
  outline: none;
  border: none;
  color: white;
  margin-top: 2vh;
  font-weight: 500;
  border-radius: 4px;
}

.continueButton:hover {
  background: hsla(234, 91%, 47%, 1) !important;

  background: linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  ) !important;

  background: -moz-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  ) !important;

  background: -webkit-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  ) !important;
}
.otpInput {
  width: 26vw !important;
}

.verifyButton {
  background: hsla(234, 91%, 47%, 1);

  background: linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  );
  width: 12vw;
  outline: none;
  border: none;
  color: white;
  /* height: 6vh; */
  font-weight: 500;
  border-radius: 4px;
}


.verifyButton:hover {
  background: hsla(234, 91%, 47%, 1) !important;

  background: linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  ) !important;

  background: -moz-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  ) !important;

  background: -webkit-linear-gradient(
    135deg,
    hsla(234, 91%, 47%, 1) 34%,
    hsla(282, 100%, 48%, 1) 100%
  ) !important;
}

@media (max-width: 450px) {
  .input-lg {
    width: 30vw !important;
  }
  .otpInput {
    width: 60vw !important;
  }
}